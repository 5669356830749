<template>
	<div class="input-container">
		<input type="text" :placeholder="languageStrings.zipcode" v-model="zipcode" />
		<input type="text" :placeholder="languageStrings.confirmZipcode" v-model="confirmZipcode" />
		<button
			:disabled="(!zipcode.length > 0 && !confirmZipcode.length > 0) || zipcode !== confirmZipcode"
			class="btn"
			:title="
				(!zipcode.length > 0 && !confirmZipcode.length > 0) || zipcode !== confirmZipcode
					? languageStrings.zipCodesMustMatch
					: languageStrings.continueText
			"
			@click="submitToParent()"
		>
			{{ languageStrings.continueText }}
		</button>
		<small v-if="(!zipcode.length > 0 && !confirmZipcode.length > 0) || zipcode !== confirmZipcode" class="warn">
			{{ languageStrings.zipCodesMustMatch }}
		</small>
	</div>
</template>

<script>
export default {
	name: "ZipcodeForm",
	props: {
		serverRules: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	components: {},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			zipcode: "",
			confirmZipcode: "",
			newAccountProperty: {
				propertyName: "",
				propertyValue: "",
				componentName: "ZipcodeForm",
			},
		};
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		submitToParent() {
			if (!this.zipcode && !this.confirmZipcode) {
				this.status.ok = false;
				this.status.message = languageErrorStrings.zipCodeFieldsDoNotMatch;
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}

			this.newAccountProperty.propertyName = "addressZipCode";
			this.newAccountProperty.propertyValue = this.zipcode;
			this.eventBus.emit("addNewAccountProperty", this.newAccountProperty);

			this.eventBus.emit("advanceComponent");
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.input-container {
	display: flex;
	flex-direction: column;
	margin: 15px auto;
	padding: 15px;
	max-width: 30em;
}

input {
	font-size: 1.5em;
	border-radius: 12px;
}

small {
	font-size: 1rem;
}

button.btn:disabled {
	background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(19, 1, 1, 0.3)) !important;
	color: light-dark(rgba(16, 16, 16, 0.3), rgba(255, 255, 255, 0.3)) !important;
	border-color: light-dark(rgba(118, 118, 118, 0.3), rgba(195, 195, 195, 0.3)) !important;
}
</style>
