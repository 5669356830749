<template>
	<div id="birthdate">
		<div class="input-container">
			<div id="birthdate-container">
				<div>
					<label for="birthDate">{{ languageStrings.birthDate }}</label>
					<input
						id="birthDate"
						:class="errorState ? 'date-error' : ''"
						type="date"
						:placeholder="languageStrings.birthDate"
						:title="languageStrings.birthDate"
						v-model="birthDate"
					/>
					<span class="clear-item" :title="languageStrings.clearThisFilter" @click="birthDate = new Date().toISOString().split('T')[0]">x</span>
				</div>
			</div>
			<button class="btn" :title="languageStrings.continueText" @click="submitToParent()">{{ languageStrings.continueText }}</button>
		</div>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";

export default {
	name: "BirthDate",
	props: {
		systemSettings: Object,
		playerState: Object,
		countryList: Array,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	components: {},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			// monthStrings: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
			// month: 0,
			// day: 0,
			// year: 0,
			errorState: false,
			birthDate: new Date().toISOString().split("T")[0],
			newAccountProperty: {
				propertyName: "",
				propertyValue: "",
				componentName: "BirthDate",
			},
		};
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		submitToParent() {
			if (!this.birthDate) {
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.birthDateIsRequired;
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}

			this.newAccountProperty.propertyName = "birthDate";
			this.newAccountProperty.propertyValue = this.birthDate;
			this.eventBus.emit("addNewAccountProperty", this.newAccountProperty);

			this.eventBus.emit("advanceComponent");
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.input-container {
	display: flex;
	flex-direction: column;
	margin: 15px auto;
	padding: 15px;
	max-width: 30em;
}

input {
	font-size: 1.5em;
	border-radius: 12px;
}

#birthdate-container > div {
	display: flex;
	align-items: center;
	justify-content: center;
}

.clear-item {
	align-self: center;
	justify-self: center;
	background: #5a87bf;
	padding: 0px 5px 2px;
	margin: 0;
	border-radius: 100%;
	cursor: pointer;
	font-size: 0.8em;
}

.date-error {
	border: 3px red solid;
}
</style>
